import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {FUNNEL_STAGES} from '@halp/util';
import {useUser} from '../../Users';
import {
	useCoachOnboardingMessages,
	type CoachMessageKey,
	type CoachMessage,
} from './CoachOnboardingMessages';
import type {ReactNode} from 'react';

export interface StoredMessage {
	key: CoachMessageKey;
	read: boolean;
}

const CoachMessagesContext = createContext<{
	messages: CoachMessage[];
	unreadMesagesCount: number;
	dispatchCoachMessage: (messageKey: CoachMessageKey) => void;
	readAllMessages: () => void;
}>({
	messages: [],
	unreadMesagesCount: 0,
	// eslint-disable-next-line no-empty-function
	dispatchCoachMessage: () => {},
	// eslint-disable-next-line no-empty-function
	readAllMessages: () => {},
});

interface Props {
	children: ReactNode;
}

export function CoachMessagesProvider({children}: Props) {
	const {user} = useUser();
	const [messages, setMessages] = useState<StoredMessage[]>([]);
	const onboardingMessages = useCoachOnboardingMessages();

	const dispatchCoachMessage = useCallback(
		(messageKey: CoachMessageKey) => {
			const messagePreviouslyRead = localStorage.getItem(messageKey);
			if (messages.find((m) => messageKey === m.key) === undefined) {
				if (messagePreviouslyRead) {
					setMessages((prevMessages) => [
						{key: messageKey, read: true},
						...prevMessages,
					]);
				} else {
					setMessages((prevMessages) => [
						{key: messageKey, read: false},
						...prevMessages,
					]);
				}
			}
		},
		[messages],
	);

	function readAllMessages() {
		setMessages((prevMessages) => {
			return prevMessages.map((message) => {
				localStorage.setItem(message.key, 'true');
				return {...message, read: true};
			});
		});
	}

	useEffect(() => {
		const studentStageIndex = user?.studentProfile?.funnelStage
			? FUNNEL_STAGES.indexOf(user?.studentProfile?.funnelStage)
			: 0;

		FUNNEL_STAGES.forEach((stage) => {
			const currentStageIndex = FUNNEL_STAGES.indexOf(stage);
			if (studentStageIndex >= currentStageIndex) {
				if (stage === 'SIGNUP') {
					dispatchCoachMessage('SIGNUP');
				}

				if (
					stage === 'VERIFICATION' &&
					user?.studentProfile?.qualificationComplete
				) {
					dispatchCoachMessage('GET_STARTED_QUALIFIED');
					if (user.studentProfile?.onshore) {
						dispatchCoachMessage('DOC_UPLOAD_ONSHORE');
					} else {
						dispatchCoachMessage('DOC_UPLOAD_OFFSHORE');
					}
				}

				if (stage === 'GET_STARTED' && user?.studentProfile?.verified) {
					switch (user?.studentProfile?.coach?.id) {
						case '14':
							dispatchCoachMessage('COACH_INTRO_MWILA');
							break;
						case '10':
							dispatchCoachMessage('COACH_INTRO_ABBY');
							break;
						case '12':
							dispatchCoachMessage('COACH_INTRO_JOSH');
							break;
						case '21':
							dispatchCoachMessage('COACH_INTRO_NAMKA');
							break;
					}
				}
			}
		});
	}, [dispatchCoachMessage, user, messages]);

	const value = useMemo(() => {
		return {
			messages: messages.map((message) => onboardingMessages[message.key]),
			unreadMesagesCount: messages.filter((message) => !message.read).length,
			dispatchCoachMessage,
			readAllMessages,
		};
	}, [dispatchCoachMessage, messages, onboardingMessages]);

	return (
		<CoachMessagesContext.Provider value={value}>
			{children}
		</CoachMessagesContext.Provider>
	);
}

export function useCoachMessages() {
	return useContext(CoachMessagesContext);
}
