import {useUser} from '@halp/foundation/Users';
import {useFeature} from '@halp/foundation/Features';
import {Banner} from '@halp/ui';
import {dayjs} from '@halp/util/datetime';
import {ArchivedBanner} from './ArchivedBanner/ArchivedBanner';
import {DeadlineBanner} from './DeadlineBanner/DeadlineBanner';

export function StudentBanner() {
	const {user} = useUser();
	const deadline = useFeature('deadline');

	if (
		user?.studentProfile?.archived &&
		user?.studentProfile?.archivalReason === 'inactive_unresponsive'
	) {
		return (
			<Banner color="red">
				<ArchivedBanner />
			</Banner>
		);
	}

	if (
		deadline &&
		user?.studentProfile?.deadline &&
		dayjs(user?.studentProfile?.deadline).diff(new Date(), 'days') > 0 &&
		['ACCEPTED', 'VISA'].includes(user?.studentProfile.funnelStage)
	) {
		return (
			<Banner color="red">
				<DeadlineBanner
					funnelStage={user?.studentProfile.funnelStage}
					deadline={user?.studentProfile?.deadline}
					studyDate={user?.studentProfile?.targetStudyDate}
				/>
			</Banner>
		);
	}

	return null;
}
