import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {useAnalytics} from './hooks/useAnalytics';

export function Analytics() {
	const router = useRouter();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.page();
		router.events.on('routeChangeComplete', () => analytics.page());
		return () => {
			router.events.off('routeChangeComplete', () => analytics.page());
		};
	}, [router.events, analytics]);

	return null;
}
